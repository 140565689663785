import { FunctionComponent } from "react";
import styles from "./Errors.module.scss";

interface IErrorProps {
	msg: string;
	errorCode?: number;
}

export const Errors: FunctionComponent<IErrorProps> = (props: IErrorProps) => {
	const { msg, errorCode } = props;

	const loadErrorMessage = () => {
		return `Error ${errorCode} - ${msg}`;
	};

	return (
		<div className={styles.errorsContainer}>
					
				<div className={styles.textContainer}>
					{errorCode ? (
						<p>
							<strong>{loadErrorMessage()}</strong>
						</p>
					) : (
						<p>
							<strong>{msg}</strong>
						</p>
					)}
					<p>
						{
							"Please put a valid token in the url"
						}
					</p>
				</div>
			</div>
	);
};
