import { FunctionComponent, useState } from "react";
import styles from "./PluginContainer.module.scss";
import { TextField, Dropdown, DropdownOption } from '@o4c/plugin-components';
import { ButtonsContainer } from "../buttons/ButtonsContainer";

interface PluginItems {
  removeItem: (id: number) => void,
	name: string;
	platform: string;
    url: string;
    id: number;
}

export const PluginContainer: FunctionComponent<PluginItems> = (props: PluginItems) => {
	const { name, platform, url, removeItem, id } = props;
    const [edit, setEdit] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [urlCheck, setUrl] = useState<string>(url);
    const [nameQuery, setName] = useState<string>(name);

    const editPlugin = (id: number) =>  {setEdit(!edit)
    }
    const disablePlugin = (id: number) =>  {}


    const platforms = ["iOS", "Android", "plugin", "webapp"]
    let platformsElement = platforms.map(function(item) {
      return <DropdownOption
      
      label={item}
      value={item}
    />;
    });
	return (
    <div className={styles.ItemsContainerRow}>

		<div className={styles.ItemsContainer}>
      <div className={styles.div}>
			<TextField
  disabled={edit}
  name="value"
  required
  type="text"
  value={nameQuery}
  onChange={e => setName((e.target as HTMLInputElement).value)}
/>
</div>
<div className={styles.div}>
<Dropdown
  disabled={edit}
  label="Dropdown"
  name="example"
  placeholder="Placeholder"
  value={platform}
>
  {platformsElement}
  
</Dropdown>
</div>
<div className={styles.div}>
<TextField
  disabled={edit}
  name="value"
  required
  type="url"
  value={urlCheck}
  onChange={e => setUrl((e.target as HTMLInputElement).value)}
/>

			</div>
      </div>
      <ButtonsContainer 
      removeItem={removeItem} editItem={editPlugin} disableItem={disablePlugin} id={id} />
      </div>
	);
  
};
