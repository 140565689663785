import { FunctionComponent, useState } from "react";
import styles from "./ButtonsContainer.module.scss";
import { Icon, Switch, Button } from '@o4c/plugin-components';
interface IFunctionsProp {
	removeItem: (id: number) => void,
  editItem: (id: number) => void,
  disableItem: (id: number) => void,
  id: number;
}

export const ButtonsContainer: FunctionComponent<IFunctionsProp> = (props: IFunctionsProp) => {
	const { removeItem, editItem, disableItem, id } = props;
  const [switchValue, setSwitchValue] = useState<boolean>(false);
	return (
		<div className={styles.ButtonsContainer}>
      <Button
  color="primary"
  display="inline"
  size="default"
  type="invisible"
  onClick={removeItem(id)}
>
      <Icon color="primary" name='delete' />
      </Button>
      <Button
  color="primary"
  display="inline"
  size="default"
  type="invisible"
  onClick={editItem}
>
      <Icon color="primary" name='edit' />
      </Button>
      
  
      </div>
	);
  
};
