import './App.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import {useEffect,useState} from "react";
import { Errors } from "./components/errors/Errors";
import { PluginContainer } from "./components/container/PluginContainer";

const errorMessage = "No token found in url!"

function App() {
  const [error, setError] = useState<boolean>(false); 
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  const token: string = query.get("token") || "";
  const removePlugin = (id: number) =>  {

  }
  const items = [
  { 
    id: 1,
    name:'test',
     platform: 'iOS',
      url:"google.com"},
  { 
    id: 2,
    name:'test2',
    platform: 'iOS',
     url:"google.com"}
  ]

  let platformsElement = items.map(function(item) {
    return <PluginContainer
      name={item.name} platform={item.platform} 
      url={item.url} id={item.id} removeItem={removePlugin}/>
  });

  const {} = useTranslation(); 
  useEffect(() => {
		if (!token) {
			return setError(true);
		}
  }, [token]);

  return (
      <div>
        { error ? (
         <Errors msg={errorMessage} />


        ) : (
          <div className='container'>
            {platformsElement}
            <PluginContainer name='test' platform='iOS' url="google.com" removeItem={removePlugin} id={3} />
          </div>)
        }
      </div>
    )
}

export default App;
